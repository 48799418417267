.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-s {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(191, 0, 0); /*#b80000*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 80%;
  /* max-width: 300px; */
  margin-bottom: 10px;
  font-size: 1rem;
  @media (max-width: 550px) {
    font-size: .7rem;
  }
  outline: none;
}

button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}