.background-image {
    position: fixed;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('./bcBackground.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
}

.content-holder{
    /* top: 35.6%; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10%;
    /* align-items: center; */
}

.signUp-box{
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 3.125rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* @media (min-width: 1379px) {
        margin-right: 5%;
    } */
    /* @media (max-width: 1379px) {
        margin-bottom: 5%;
    } */

    margin-right: 40px;
    margin-left: 40px;

    /* position: absolute; */
    width: 508px;
    /* width: 46.9%; */
    /* width: 30.2%; */
    /* height: 346px; */
    /* height: 80%; */
    /* left: 1047px; */
    right: 7%;
    /* left: 62.3%; */
    top: 35.6%;
    /* top: 320px; */
    /* bottom: 233px; */

    background: linear-gradient(97.24deg, #949494 12.25%, #FFFFFF 209.44%);
    mix-blend-mode: normal;
    border: 2px solid #000000;
    border-radius: 39px;

}

.signUp-box-heading{
    /* position: absolute; */
    /* width: 200px; */
    /* height: 50px; */
    left: 1250px;
    top: 391px;

    font-family: 'Futura';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 1.7em;
    /* @media (max-width: 400px) {
        font-size: 26.25px;
    } */
    /* @media (max-width: 400px) {
        font-size: 1.7em;
    } */

    text-transform: capitalize;

    color: #000000;
}

.signUp-box-subheading{
    /* position: absolute; */
    /* width: 279px; */
    height: 25px;
    left: 1190px;
    top: 446px;

    font-family: 'Futura';
    font-style: normal;
    font-weight: 400;
    /* font-size: 20px;
    line-height: 25px; */
    /* identical to box height */
    /* @media (max-width: 550px) {
        font-size: 15px;
        line-height: 20px;
    } */
    font-size: 15px;
    line-height: 20px;


    color: #000000;
}

.hero-text{
    /* position: absolute; */
    /* width: 732px; */
    /* height: 250px; */
    /* left: 132px; */
    left: 7.85%;
    /* top: 320px; */
    top: 35.6%;
    margin-left: 5%;

    font-family: 'Futura';
    font-style: normal;
    font-weight: 600;
    /* font-size: 85px; */
    /* font-size: 5.31rem; */
    /* line-height: 125px; */
    padding-bottom: 5%;
    text-transform: capitalize;
    color: #FFFFFF;
    max-height: 50%;

    /* @media (max-width: 750px) { */
        font-size: 4.1rem;
        line-height: 90px;
    /* } */
    /* @media (max-width: 550px) {
        font-size: 4rem;
        line-height: 90px;

    }

    @media (max-width: 34.4rem) {
        font-size: 4rem;
        line-height: 90px;

    }

    @media (max-width: 25rem) {
        font-size: 3rem;

    } */

    

}