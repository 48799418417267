/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: .5rem 2rem;
    box-sizing: border-box;
  }
  
  .navbar-side {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .navbar-logo {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    padding-left: 3%;
    padding-top: 1rem;
  }
  
  .navbar-links {
    display: flex;
    gap: 1rem;
    padding-right: 3%;
  }
  
  .navbar-link {
    text-decoration: none;
    color: #fff;
    font-size: 1.1rem;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .navbar-link:hover {
    color: #ccc;
  }
  